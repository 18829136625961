
import { defineComponent } from "vue";
import getUserPreferredLang from "@/utils/getUserPreferredLang";

const VALID_LANGS = ["es", "cat"];
const userPreferredLang = getUserPreferredLang();

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    // Validate lang
    if (!VALID_LANGS.includes(to.params.lang as string)) {
      next({
        name: "404",
        params: { lang: userPreferredLang, catchAll: "404" },
      });
    } else {
      next();
    }
  },
});
